<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <b>SMS Templates</b>
            <div class="card-header-actions">
              <CButton
                color="primary"
                @click="addTemplateClicked()">
                Add Template
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              striped
              :items="items"
              :fields="fields"
              :items-per-page="10"
              :itemsPerPageSelect="true"
              column-filter
              table-filter
              :loading="loading"
              border
            >
            <template #update="{item}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    variant="outline"
                    size="sm"
                    @click="updateClicked(item)">
                    Update
                  </CButton>
                </td>
              </template>
              
              <template #delete="{item}">
                <td class="py-2">
                  <CButton
                    color="danger"
                    square
                    variant="outline"
                    size="sm"
                    @click="deleteModal = true; deleteItem = item.key;">
                    Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Delete template?"
      :show.sync="deleteModal"
      @update:show="onCloseModal"
    >
      <p>Are you sure you want to delete this item?</p>
      <p>Key: <b>{{ deleteItem }}</b></p>
    </CModal>
  </div>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'
import TimeUtils from '../../common/TimeUtils'

const fields = [
  { key: 'pos', label: 'No.'},
  { key: 'key', label: 'Key'},
  { key: 'value', label: 'Value'},
  { key: 'description', label: 'Description'},
  { key: 'time_created', label: 'Time Created'},
  { key: 'time_updated', label: 'Time Updated'},
  { 
    key: 'update', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  },
  { 
    key: 'delete', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
];

export default {
  name: 'SmsTemplate',
  created() {
    this.getSmsTempates();
  },
  data () {
    return {
      items: [],
      fields,

      loading: false,
      showAlert: false,

      deleteModal: false,
      deleteItem: ""
    }
  },
  methods: {
    getSmsTempates() {
      let _this = this;
      _this.loading = true;
      _this.items = [];
      
      AxiosBase.get("/sms_template/list", {
        params: {}
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        if (res && res.data) {
          for (let item of res.data) {
            item.time_created = TimeUtils.formatTime(item.time_created);
            item.time_updated = TimeUtils.formatTime(item.time_updated);

            _this.items.push(item);
          }
        }

        _this.loading = false;
      });
    },
    deleteSmsTemplate(key) {
      let _this = this;
      AxiosBase.delete("/sms_template/delete", {
        params: {
          key: key
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        if (res && res.status === 200) {
          _this.getSmsTempates();
        } else {
          console.log(res);
        }
      });
    },
    onCloseModal(status, evt, accept) {
      if (accept) {
        this.deleteSmsTemplate(this.deleteItem);
      }
    },
    updateClicked(item) {
      this.$router.push(`/smstemplates/update/${item.key}`);
    },
    addTemplateClicked() {
      this.$router.push(`/smstemplates/add`);
    }
  }
}
</script>
